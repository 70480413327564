<template>
  <k-ajax-multi-select
    :value="value"
    @input="$emit('input', $event)"
    :multiple="multiple"
    labelBy="Text"
    :validator="validator"
    :getOptions="GetCikkek"
  >
    <template slot="singleLabel" slot-scope="props">
      <div>{{ props.option.Id }} - {{ props.option.Text }}</div>
    </template>
    <template slot="option" slot-scope="props">
      <div>{{ props.option.Id }} - {{ props.option.Text }}</div>
    </template>
  </k-ajax-multi-select>
</template>

<script>
import { mapGetters } from 'vuex';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { hirService } from '../../services/HirService';

export default {
  name: 'cikk-valaszto',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    async GetCikkek(keresoszoveg) {
      let options = [];
      if (keresoszoveg.length > 2) {
        try {
          let cikkek = await hirService.GetHirek({
            superAdminFl: true,
            keresoszoveg,
          });

          options = cikkek.map(m => {
            return { Id: m.Id, Text: m.Cim };
          });
        } catch (error) {
          NotificationFunctions.AjaxError({ error });
        }
      }
      return options;
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
